import axios from 'axios';

const RMSServer = axios.create({
    baseURL: 'https://retailmarketing.kz:1337/api',
    headers: {}
})

export default {
    RMSServer,
}

