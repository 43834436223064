<template>
  <div class="main-dots">
    <div class="main-header__selected-services">
      <div v-for="(dot, index) in dots"
           :class="'dot' + (dotActive === index ? ' dot-active' : '')"
           @click="onClickDot(index)"
           @touchend="onClickDot(index)"
      >
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

window.onbeforeunload = function () {
  window.scrollTo(0,0);
};

export default {
  name: 'MainDots',
  data() {
    return {
      dotActive: 0,
      lastScroll: {
        Y: 0,
        pos: 0,
        scrolled: true
      },
      dots: [
        {
          to: function(docs) {
            let inside = document.getElementsByClassName('main-header')[0];

            let scrollTop = {
              top: docs[0],
              behavior: 'smooth'
            };

            this.dotActive = 0;

            window.scrollTo(scrollTop);

            return scrollTop.top;
          }
        },
        {
          to: function(docs) {
            let inside = document.getElementsByClassName('main-network-of-advertising-screens-in-retail')[0];
            let inside2 = document.getElementsByClassName('main-body')[0];
            let inside3 = document.getElementsByClassName('main-tag__content')[0];

            let scrollTop = {
              top: docs[1],
              behavior: 'smooth'
            };

            this.dotActive = 1;

            window.scrollTo(scrollTop);

            return scrollTop.top;
          }
        },
        {
          to: function(docs) {
            let inside = document.getElementsByClassName('main-modern-management-through-an-online-platform')[0];
            let inside2 = document.getElementsByClassName('main-body')[0];
            let inside3 = document.getElementsByClassName('main-tag__content')[0];

            let scrollTop = {
              top: docs[2],
              behavior: 'smooth'
            };

            this.dotActive = 2;

            window.scrollTo(scrollTop);

            return scrollTop.top;
          }
        },
        {
          to: function(docs) {
            let inside = document.getElementsByClassName('main-ways-to-measure-effectiveness')[0];
            let inside2 = document.getElementsByClassName('main-body')[0];
            let inside3 = document.getElementsByClassName('main-tag__content')[0];

            let scrollTop = {
              top: docs[3],
              behavior: 'smooth'
            };

            this.dotActive = 3;

            window.scrollTo(scrollTop);

            return scrollTop.top;
          }
        }
      ],
      documents: [
          0,
          0,
          0,
          0
      ],
      keys: {
        37: 1,
        38: 1,
        39: 1,
        40: 1
      },
      wheelOpt: '',
      wheelEvent: '',
      touchstart: '',
      touchmove: '',
      touchend: '',
      touchenter: '',
      touchleave: '',
      touchcancel: '',
      orientationchange: '',
      lastScrollTop: 0,
      lastScrollScreen: 0,
      currentScrollScreen: 0,
      changeScrollScreen: true,
      itsStartTop: 0,
      itsBetweenHeightTop: 0,
      itsEndTop: 0,
      itsFooter: false,
      itsCounter: 0,
    }
  },
  computed: {
    ...mapGetters({
      showModal: 'getGenerallyShowModal'
    })
  },
  mounted() {

    if((/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))) {
      window.addEventListener('wheel', this.onMouseMobileWindow, { passive: false });
      window.addEventListener('scroll', this.onScrollMobileWindow2, { passive: false });
      window.addEventListener('mousewheel', this.onMouseWheelMobileWindow, { passive: false });
    } else {
      window.addEventListener('wheel', this.onMouseWindow, { passive: false });
      window.addEventListener('scroll', this.onScrollWindow);
    }

    document.addEventListener('touchstart', this.onScrollMobileWindow, { passive: false });
    document.addEventListener('touchmove', this.onScrollTouchmoveWindow, { passive: false });
    document.addEventListener('touchend', this.onScrollTouchendWindow, { passive: false });
    document.addEventListener('touchenter', function(e) {
      this.touchenter = e;
      e.preventDefault();
      e.stopPropagation();
    });
    document.addEventListener('touchleave', function(e) {
      this.touchleave = e;
      e.preventDefault();
      e.stopPropagation();
    });
    document.addEventListener('touchcancel', function(e) {
      // this.touchcancel = e;
      e.preventDefault();
      e.stopPropagation();
    });
    document.addEventListener('orientationchange', function(e) {
      this.orientationchange = e;
      e.preventDefault();
      e.stopPropagation();
    });

    window.scroll(0, 0);

    let inside = document.getElementsByClassName('main-network-of-advertising-screens-in-retail')[0];
    let inside2 = document.getElementsByClassName('main-body')[0];
    let inside3 = document.getElementsByClassName('main-tag__content')[0];

    this.documents[1] = (inside.offsetTop + inside2.offsetTop - inside3.offsetHeight);


    inside = document.getElementsByClassName('main-modern-management-through-an-online-platform')[0];
    inside2 = document.getElementsByClassName('main-body')[0];

    if (window.innerWidth < 670) {
      this.documents[2] = (inside.offsetTop + inside2.offsetTop);
    } else if (window.innerWidth < 1435) {
      this.documents[2] = (inside.offsetTop + inside2.offsetTop);
    } else {
      this.documents[2] = (inside.offsetTop + inside2.offsetTop);
    }


    inside = document.getElementsByClassName('main-ways-to-measure-effectiveness')[0];
    inside2 = document.getElementsByClassName('main-body')[0];
    let inside4 = document.getElementsByClassName('main-modern-management-through-an-online-platform')[0];

    if (window.innerWidth < 670) {
      this.documents[3] = (inside.offsetTop + inside2.offsetTop + inside3.offsetHeight);
    } else if (window.innerWidth < 1435) {
      this.documents[3] = (inside4.offsetTop + inside4.offsetHeight + inside2.offsetTop + inside3.offsetHeight);
    } else {
      // this.documents[3] = (inside.offsetTop + inside2.offsetTop + inside3.offsetHeight);
      this.documents[3] = (inside4.offsetTop + inside4.offsetHeight + inside2.offsetTop + inside3.offsetHeight);
    }

    console.log('inside4.offsetTop + inside4.offsetHeight');
    console.log(inside4.offsetTop);
    console.log(inside4.offsetHeight);
    console.log(inside2.offsetTop);
    console.log(inside3.offsetHeight);
    console.log(inside4.offsetTop + inside4.offsetHeight + inside2.offsetTop + inside3.offsetHeight);
    console.log(this.documents[3]);

    var supportsPassive = false;

    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
      }));
    } catch(e) {}

    this.wheelOpt = supportsPassive ? { passive: false } : false;
    this.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
  },
  methods: {
    onScrollMobileWindow2(e) {

      if ((window.scrollY + 150 <= this.documents[this.documents.length - 1]) && this.itsFooter) {
        this.currentScrollScreen = this.documents.length - 2;

        this.dotActive = this.currentScrollScreen;

        let scrollTop = {
          top: this.documents[this.currentScrollScreen],
          behavior: 'smooth'
        };

        window.scrollTo(scrollTop);

        // window.scroll(0, this.documents[this.currentScrollScreen]);
        this.lastScrollTop = this.documents[this.currentScrollScreen];

        this.lastScrollScreen = this.currentScrollScreen;
        this.itsFooter = false;
      //   return;
      }
      //
      //
      // e.preventDefault();
      // e.stopPropagation();
      //
      // alert('true 3');

      // this.touchend = e;
    },
    onMouseWheelMobileWindow(e) {
      e.preventDefault();
      e.stopPropagation();

      // alert('true 3');

      // this.touchend = e;
    },
    onScrollTouchmoveWindow(e) {
      this.touchmove = e;
      this.itsCounter += 1;

      // if (!(window.scrollY - 150 > this.documents[this.documents.length - 1])) {
      //   this.itsFooter = false;
      // }
    },
    onScrollTouchendWindow(e) {
      this.touchend = e;
      this.itsEndTop = e.changedTouches[0].pageY;
      this.itsBetweenHeightTop = this.itsStartTop - ( this.itsEndTop);
      this.touchleave = (this.itsStartTop > this.itsEndTop ? 'bottom' : (this.itsStartTop < this.itsEndTop ? 'top' : ''));

      let lastScreen = this.currentScrollScreen;
      this.touchcancel = window.scrollY;

      if (window.scrollY + 150 > this.documents[this.documents.length - 1]) {
        this.itsFooter = true;
        if (this.itsStartTop < this.itsEndTop) {
          this.itsCounter += 10000;
          e.preventDefault();
          e.stopPropagation();
          window.scroll(0, window.scrollY - 150);

          // let scrollTop = {
          //   top: this.documents[this.currentScrollScreen],
          //   behavior: 'smooth'
          // };
          //
          // window.scrollTo(scrollTop);
        }
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      if (this.itsStartTop > this.itsEndTop) {
        if (this.changeScrollScreen) {
          if (this.currentScrollScreen + 1 < this.documents.length) {
            this.currentScrollScreen += 1;
            this.dotActive = this.currentScrollScreen;

            this.changeScrollScreen = false;
            setTimeout(this.changeScrollScreenS, 300);
          }
        }
      } else if (this.itsStartTop < this.itsEndTop) {
        if (this.changeScrollScreen) {
          if (this.currentScrollScreen - 1 >= 0) {
            this.currentScrollScreen -= 1;
            this.dotActive = this.currentScrollScreen;

            this.changeScrollScreen = false;
            setTimeout(this.changeScrollScreenS, 300);
          }
        }
      }

      if (this.currentScrollScreen !== lastScreen) {

        let scrollTop = {
          top: this.documents[this.currentScrollScreen],
          behavior: 'smooth'
        };

        window.scrollTo(scrollTop);
        // window.scroll(0, this.documents[this.currentScrollScreen]);
        this.lastScrollTop = this.documents[this.currentScrollScreen];
        this.dotActive = this.currentScrollScreen;

        // let scrollTop = {
        //   top: this.documents[this.currentScrollScreen],
        //   behavior: 'smooth'
        // };
        //
        // this.dotActive = this.currentScrollScreen;
        //
        // window.scrollTo(scrollTop);

        this.lastScrollScreen = lastScreen;
      }

    },
    onScrollMobileWindow(e) {

      if (window.scrollY + 150 > this.documents[this.documents.length - 1]) {
        this.itsFooter = true;
        if (this.itsStartTop < this.itsEndTop) {
          this.itsCounter += 10000;
          e.preventDefault();
          e.stopPropagation();
          window.scroll(0, window.scrollY - 5);

          // let scrollTop = {
          //   top: this.documents[this.currentScrollScreen],
          //   behavior: 'smooth'
          // };
          //
          // window.scrollTo(scrollTop);
        }
        return;
      }

      this.touchcancel = e;

      this.itsStartTop = e.changedTouches[0].pageY;

      console.log(e);
      console.log('touchstart');

      e.preventDefault();
      e.stopPropagation();

      // if (e.isTrusted) {
      //   let lastScreen = this.currentScrollScreen;
      //   this.touchcancel = window.scrollY;
      //
      //   if (window.scrollY >= this.documents[this.documents.length - 1] - 150) {
      //     this.itsFooter = true;
      //     return;
      //   }
      //
      //   if (document.body.scrollTop > 0) {
      //     if (this.changeScrollScreen) {
      //       if (this.currentScrollScreen + 1 < this.documents.length) {
      //         this.currentScrollScreen += 1;
      //
      //         this.changeScrollScreen = false;
      //         setTimeout(this.changeScrollScreenS, 1600);
      //       }
      //     }
      //   } else if (document.body.scrollTop < 0) {
      //     if (this.changeScrollScreen) {
      //       if (this.currentScrollScreen - 1 >= 0) {
      //         this.currentScrollScreen -= 1;
      //
      //         this.changeScrollScreen = false;
      //         setTimeout(this.changeScrollScreenS, 1600);
      //       }
      //     }
      //   }
      //
      //   if (this.currentScrollScreen !== lastScreen) {
      //     window.scroll(0, this.documents[this.currentScrollScreen]);
      //     this.lastScrollTop = this.documents[this.currentScrollScreen];
      //
      //     // let scrollTop = {
      //     //   top: this.documents[this.currentScrollScreen],
      //     //   behavior: 'smooth'
      //     // };
      //     //
      //     // this.dotActive = this.currentScrollScreen;
      //     //
      //     // window.scrollTo(scrollTop);
      //
      //
      //     this.lastScrollScreen = lastScreen;
      //   }
      //
      // }

      // this.touchmove = JSON.stringify(e);
    },
    changeScrollScreenS() {
      this.changeScrollScreen = true;
    },
    onMouseMobileWindow(e) {
      e.preventDefault();
      e.stopPropagation();

      // alert('true');
    },
    onScrollWindow() {
      console.log(window.scrollY);
      if (this.showModal) return;

      let inside = document.getElementsByClassName('main-header')[0];
      let inside2 = document.getElementsByClassName('main-network-of-advertising-screens-in-retail')[0];
      let inside3 = document.getElementsByClassName('main-modern-management-through-an-online-platform')[0];
      let inside4 = document.getElementsByClassName('main-ways-to-measure-effectiveness')[0];

      if (window.scrollY >= 0 && window.scrollY < this.documents[1]) {
        this.dotActive = 0;
        this.lastScroll.pos = 0;
        this.currentScrollScreen = 0;
      } else if (window.scrollY >= this.documents[1] && window.scrollY < this.documents[2]) {
        this.dotActive = 1;
        this.lastScroll.pos = 1;
        this.currentScrollScreen = 1;
      } else if (window.scrollY >= this.documents[2] && window.scrollY < this.documents[3]) {
        this.dotActive = 2;
        this.lastScroll.pos = 2;
        this.currentScrollScreen = 2;
      } else if (window.scrollY >= this.documents[3]) {
        this.dotActive = 3;
        this.lastScroll.pos = 3;
        this.currentScrollScreen = 3;
      }
    },
    async onMouseWindow(e) {
      if (this.showModal) return;

      if (this.lastScroll.pos === this.documents.length - 1) {
        if (window.scrollY < this.documents[this.lastScroll.pos]) {
          e.preventDefault();
          e.stopPropagation();
        } else {
          return;
        }
      } else {
        e.preventDefault();
        e.stopPropagation();
      }

      if (!this.lastScroll.scrolled) {

        e.preventDefault();
        e.stopPropagation();
      } else {

        let changed = false;

        if (e.deltaY > 0) {
          if (this.lastScroll.pos < this.dots.length - 1) {
            this.lastScroll.pos += 1;
            changed = true;
          }
        } else if (e.deltaY < 0) {
          if (this.lastScroll.pos > 0) {
            this.lastScroll.pos -= 1;
            changed = true;
          }
        }

        if (changed) {
          this.dotActive = this.lastScroll.pos;

          this.lastScroll.Y = await this.dots[this.lastScroll.pos].to(this.documents);

          this.lastScroll.scrolled = false;
          this.disableScroll();
          setTimeout(this.changeLastScrolled, 700);
        }
      }
    },
    changeLastScrolled() {
      this.lastScroll.scrolled = true;
      this.enableScroll();
    },
    onClickDot(index) {
      this.dotActive = index;
      this.dots[index].to(this.documents);
    },
    preventDefault(e) {
      e.preventDefault();
    },
    preventDefaultForScrollKeys(e) {
      if (this.keys[e.keyCode]) {
        this.preventDefault(e);
        return false;
      }
    },
    disableScroll() {
      window.addEventListener('DOMMouseScroll', this.preventDefault, false);
      window.addEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt);
      window.addEventListener('touchmove', this.preventDefault, this.wheelOpt);
      window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
    },
    enableScroll() {
      window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
      window.removeEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt);
      window.removeEventListener('touchmove', this.preventDefault, this.wheelOpt);
      window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
    }
  }
}
</script>

<style>
@import url('../../../assets/css/modules/main/main-dots.css');
</style>

