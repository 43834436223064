<template>
  <div class="main-ways-to-measure-effectiveness">
    <div class="main-ways-to-measure-effectiveness__content">
      <div class="main-ways-to-measure-effectiveness__inside">
        <div class="main-ways-to-measure-effectiveness__title">Способы замерить эффективность</div>
        <div class="main-ways-to-measure-effectiveness__info-ways">
          <div class="main-ways-to-measure-effectiveness__ways">
            <div class="main-ways-to-measure-effectiveness__ways-content">
              <div class="main-ways-to-measure-effectiveness__way" v-for="way in ways">
                <div :class="'main-ways-to-measure-effectiveness__way-id' + (way.active ? ' main-ways-to-measure-effectiveness__way-id__active' : '')">
                  <span>{{ way.id }}</span>
                </div>
                <div :class="'main-ways-to-measure-effectiveness__way-name' + (way.active ? ' main-ways-to-measure-effectiveness__way-name__active' : '')">{{ way.name }}</div>
              </div>
            </div>
          </div>
          <div class="main-ways-to-measure-effectiveness__images">
            <div class="main-ways-to-measure-effectiveness__views">
              <div class="main-ways-to-measure-effectiveness__view"
                   v-for="(way, index) in ways"
                   :style="{ left: (index * leftWindow()) + 'px'}"
              >
                <img class="main-ways-to-measure-effectiveness__view-image" :src="way.imageUrl">
              </div>
            </div>
            <div class="main-ways-to-measure-effectiveness__lines">
              <div class="main-ways-to-measure-effectiveness__line"
                   v-for="(way, index) in ways"
                   :style="{ width: Math.floor(480 / ways.length) + 'px' }"
                   v-if="ways.find((e, i) => (i >= index ? e.active : false)) !== undefined"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudienceReach from '../../../assets/images/ways-to-measure-effectiveness/audience-reach.png';
import SalesUplift from '../../../assets/images/ways-to-measure-effectiveness/sales-uplift.png';
import AudienceResponse from '../../../assets/images/ways-to-measure-effectiveness/audience-response.png';
import { mapGetters } from "vuex";

export default {
  name: 'MainWaysToMeasureEffectiveness',
  data() {
    return {
      ways: [
        {
          id: 1,
          name: 'Охват аудитории – на основе реального трафика (чеки). Eye tracking будет доступен в выбранных магазинах',
          imageUrl: AudienceReach,
          active: true
        },
        {
          id: 2,
          name: 'Sales uplift – фактическое влияние размещений на актуальные продажи',
          imageUrl: SalesUplift,
          active: false
        },
        {
          id: 3,
          name: 'Отклик аудитории – QR коды, промокоды, опросы в приложении Magnum Сlub',
          imageUrl: AudienceResponse,
          active: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      showModal: 'getGenerallyShowModal'
    })
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    leftWindow() {
      return window.innerWidth < 1125 ? ((window.innerWidth / 100) * (window.innerWidth < 670 ? 90 : 75)) : 484;
    },
    handleScroll() {
      if (this.showModal) return;

      let inside = document.getElementsByClassName('main-ways-to-measure-effectiveness')[0];
      let inside4 = document.getElementsByClassName('main-ways-to-measure-effectiveness__content')[0];
      let inside5 = document.getElementsByClassName('main-tag')[0];

      let inside7 = document.getElementsByClassName('main-ways-to-measure-effectiveness__inside')[0];

      let inside10 = document.getElementsByClassName('main-ways-to-measure-effectiveness__title')[0];
      let inside11 = document.getElementsByClassName('main-ways-to-measure-effectiveness__ways')[0];
      let inside12 = document.getElementsByClassName('main-ways-to-measure-effectiveness__images')[0];

      let height = 200;
      let top = (inside.offsetTop + (inside5.offsetHeight * 2) + ((window.innerHeight / 2) / 2));
      let height2 = inside7.offsetHeight + 176;

      if (window.innerWidth >= 1435) {
        height2 += 100;
      }
      if (window.innerWidth <= 1125) {
        height2 = (inside10.offsetHeight + inside11.offsetHeight + inside12.offsetHeight + 176);
      }
      if (window.innerWidth <= 670 && window.innerHeight >= 700) {
        height2 = (inside10.offsetHeight + inside11.offsetHeight + inside12.offsetHeight + (window.innerHeight / 2));
      }

      if (window.scrollY > (top + height2 - 276) && window.innerWidth > 670 && window.innerWidth <= 1125) {
        inside7.style.position = 'absolute';
        inside7.style.width = '100%';
        inside7.style.top = (inside4.offsetHeight - 276 - (height * 3)) + 'px';
      } else if (window.scrollY > top) {
        inside7.style.position = 'fixed';
        // inside7.style.top = (((window.innerHeight / 2) / 2) / 2) + 'px';
        inside7.style.bottom = '120px';
        document.getElementsByClassName('main-ways-to-measure-effectiveness')[0].style.margin = '150px 0 0 0';
        if (window.innerWidth <= 670) {
          inside7.style.width = '90%';
        } else if (window.innerWidth <= 1435) {
          inside7.style.width = '75%';
        } else {
          inside7.style.width = '61.458%';
        }
      } else {
        inside7.style.position = 'relative';
        inside7.style.width = '100%';
      }

      inside4.style.setProperty('height', ((height2 + (height * 3)) + 'px'), 'important');

      // if (window.innerHeight < inside4.offsetHeight) {
      //
      //   if ((window.scrollY + window.innerHeight) >= (top + height2) &&
      //       (window.scrollY + window.innerHeight) < (top + height2 + height) &&
      //       !this.ways[0].active
      //   ) {
      //
      //     this.setPositionImages(0);
      //   } else if ((window.scrollY + window.innerHeight) >= (top + height2 + height) &&
      //       (window.scrollY + window.innerHeight) < (top + height2 + (height * 2)) &&
      //       !this.ways[1].active
      //   ) {
      //
      //     this.setPositionImages(1);
      //   } else if ((window.scrollY + window.innerHeight) >= (top + height2 + (height * 2)) &&
      //       (window.scrollY + window.innerHeight) < (top + height2 + (height * 3)) &&
      //       !this.ways[2].active
      //   ) {
      //
      //     this.setPositionImages(2);
      //   }
      //
      //   return;
      // }

      if (window.scrollY >= top && window.scrollY < (top + height) && !this.ways[0].active) {
        this.setPositionImages(0);
      } else if (window.scrollY >= (top + height) && window.scrollY < (top + (height * 2)) && !this.ways[1].active) {
        this.setPositionImages(1);
      } else if (window.scrollY >= (top + (height * 2)) && window.scrollY < (top + (height * 3)) && !this.ways[2].active) {
        this.setPositionImages(2);
      }

    },
    setPositionImages(index) {
      let inside = document.getElementsByClassName('main-ways-to-measure-effectiveness__views')[0];
      let inside2 = document.getElementsByClassName('main-ways-to-measure-effectiveness__content')[0];
      let inside3 = document.getElementsByClassName('main-ways-to-measure-effectiveness__ways')[0];
      let inside4 = document.getElementsByClassName('main-ways-to-measure-effectiveness__view')[index];

      this.ways.map((e, indexE) => {
        e.active = indexE === index;

        return e;
      });

      if (window.innerWidth < 1125) {

        inside.style.left = `-${(inside.offsetWidth * index)}px`;
        inside.style.clip = `rect(auto, ${(inside.offsetWidth * (index + 1))}px, auto, ${(inside.offsetWidth * index)}px)`;
        inside3.style.left = -(inside.offsetWidth * index) + 'px';
        inside3.style.clip = `rect(auto, ${(inside.offsetWidth * (index + 1))}px, auto, ${(inside.offsetWidth * index)}px)`;
        inside4.style.setProperty('width', inside.offsetWidth + 'px', 'important');
      } else {
        inside.style.left = `-${(484 * index)}px`;
        inside.style.clip = `rect(auto, ${(484 * (index + 1))}px, auto, ${(484 * index)}px)`;
      }
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/main-ways-to-measure-effectiveness.css');
</style>

