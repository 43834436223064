export default {
    state: {
        notifications: []
    },
    getters: {
        getNotifications(state) {
            return state.notifications;
        }
    },
    mutations: {
        SET_NOTIFICATION(state, data) {
            state.notifications.push(data);
        }
    },
    actions: {
        SET_NOTIFICATION(context, data) {
            if (!data.fullTime) {
                data.fullTime = 600;
                data.time = 600;
            } else if (!data.time) {
                data.time = data.fullTime;
            }

            context.commit('SET_NOTIFICATION', data);

            let inside = document.getElementsByTagName('body')[0];

            if (data.type === 'error') {
                inside.style.boxShadow = 'inset 0 0 1000px #fe345b';

                setTimeout(() => {
                    let inside = document.getElementsByTagName('body')[0];
                    inside.style.boxShadow = 'inset 0 0 0 rgba(255, 0, 0, 0)';
                }, 500);
            } else if (data.type === 'success') {
                inside.style.boxShadow = 'inset 0 0 1000px #2fbe42';

                setTimeout(() => {
                    let inside = document.getElementsByTagName('body')[0];
                    inside.style.boxShadow = 'inset 0 0 0 rgba(255, 0, 0, 0)';
                }, 500);
            }
        }
    }
}

