<template>
  <div id="app">
    <r-m-s-notification></r-m-s-notification>
    <router-view />
  </div>
</template>

<script>
import ApiService from "./services/api";
import RMSNotification from "./modules/core/modals/RMSNotification";

export default {
  name: 'App',
  components: {
    RMSNotification
  },
  mounted() {
    this.getLogo();
  },
  methods: {
    async getLogo() {
      await ApiService.getLogo()
          .then((response) => {
            console.log('RESPONSE');
            console.log(response);
          }).catch((error) => {
            console.log('RESPONSE ERROR');
            console.log(error);
          });
    }
  }
}
</script>

<style>
@import url('./assets/css/app.css');
</style>
