<template>
  <div class="main-footer">
    <div class="main-footer__content">
      <span class="main-footer__email">{{ email }}</span>
      <div class="main-footer__application">
        <button class="main-footer__application-button" @click="openModal" @touchend="openModal">Заявка</button>
        <button class="main-footer__application-shadow">Заявка</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      email: 'info@retailmarketing.kz'
    }
  },
  methods: {
    openModal() {
      this.$emit('openModal');
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/main-footer.css');
</style>
