<template>
  <div class="main-tag">
    <div class="main-tag__content">
      <div class="main-tag__text">Retail Marketing Solutions</div>
      <div class="main-tag__text">x</div>
      <svg class="main-tag__magnum">
        <use xlink:href="../../../../src/assets/images/magnum.svg#icon"></use>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'MainTag',
  computed: {
    ...mapGetters({
      showModal: 'getGenerallyShowModal'
    })
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.showModal) return;

      let inside = document.getElementsByClassName('main-body')[0];
      let inside2 = document.getElementsByClassName('main-tag')[0];
      let inside3 = document.getElementsByClassName('main-tag__content')[0];

      if (window.scrollY > inside.offsetTop) {
        inside3.style.position = 'fixed';
        inside2.style.height = inside3.offsetHeight + 'px';
      } else {
        inside3.style.position = 'relative';
        inside2.style.height = 'auto';
      }

    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/main-tag.css');
</style>

