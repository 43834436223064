import Vue from 'vue'
import Router from 'vue-router'
import Main from './modules/main/components/Main'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main
        }
    ]
})
