<template>
  <div class="rms-notifications">
    <div class="rms-notification" v-for="(notification, index) in notifications">
      <div class="rms-notification__content">
        <div class="rms-notification__inside">
          <div :class="'rms-notification__icon' + types[notification.type].iconClass" v-html="types[notification.type].icon"></div>
          <div class="rms-notification__message">
            {{ notification.message }}
          </div>
        </div>
        <div class="rms-notification__close" @click="closeNotification(index)" @touchend="closeNotification(index)">&#10006;</div>
      </div>
      <div :class="'rms-notification__line' + types[notification.type].lineClass"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RMSNotification',
  data() {
    return {
      types: {
        error: {
          icon: '&#9888;',
          iconClass: ' rms-notification__icon-error',
          lineClass: ' rms-notification__line-error',
        },
        success: {
          icon: '<div class="success"><div class="success-content">&#x2714;</div></div>',
          iconClass: ' rms-notification__icon-success',
          lineClass: ' rms-notification__line-success',
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'getNotifications'
    })
  },
  mounted() {
    this.timeOut();
  },
  methods: {
    timeOut() {
      if (this.notifications.length) {
        for (let i = 0; i < this.notifications.length; i++) {
          let notif = this.notifications[i];

          if (notif.time <= 0) {
            this.closeNotification(i);
          } else {
            let time1per = (notif.fullTime / 100);
            let inside = document.getElementsByClassName('rms-notification')[i];
            let inside2 = document.getElementsByClassName('rms-notification__line')[i];
            let width1per = (inside.offsetWidth / 100);
            inside2.style.width = Math.floor(Math.floor(notif.time / time1per) * width1per) + 'px';
            notif.time -= 1;
          }
        }
      }

      setTimeout(this.timeOut, 1);
    },
    closeNotification(index) {
      this.notifications.splice(index, 1);
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/core/modals/r-m-s-notification.css');
</style>
