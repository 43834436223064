import Servers from './servers';

export default {
    serverInstance: Servers.RMSServer,
    async getInventory() {
        return (await Servers.RMSServer.get('/inventory'));
    },
    async getLogo() {
        return (await Servers.RMSServer.get('/logo'));
    },
    async postOrders(data) {
        return (await Servers.RMSServer.post('/orders', data));
    }
}

