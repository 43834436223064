export default {
    state: {
        generallyShowModal: false
    },
    getters: {
        getGenerallyShowModal(state) {
            return state.generallyShowModal;
        }
    },
    mutations: {
        SET_GENERALLY_SHOW_MODAL(state, data) {
            state.generallyShowModal = data;
        }
    },
    actions: {}
}

