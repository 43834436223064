<template>
  <div class="main-header">
    <div class="main-header__content">
      <div class="main-header__title">
        <img :src="logo" alt="logo">
      </div>
      <div class="main-header__info-about-company">
        <div class="main-header__info-about-company-inside">
          <div class="main-header__company">
            <p class="main-header__company-name">{{ fullNameCompany }}</p>
            <span class="main-header__description-company">{{ descriptionCompany }}</span>
          </div>
          <div class="main-header__list-services">
            <p v-for="service in services">{{ service.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogotypeRMS from '../../../assets/images/RMS.svg';

export default {
  name: 'MainHeader',
  components: {
    LogotypeRMS
  },
  data() {
    return {
      nameCompany: 'RMS',
      logo: LogotypeRMS,
      fullNameCompany: 'Retail Marketing Solutions',
      descriptionCompany: 'Экосистема рекламных сервисов в ритейле, помогающая эффективно контактировать с покупателями и влиять на увеличение продаж',
      dots: [
        {
          active: true,
          to: function() {
            let inside = document.getElementsByClassName('main-header')[0];

            let scrollTop = {
              top: (inside.offsetTop),
              behavior: 'smooth'
            };

            window.scrollTo(scrollTop);
          }
        },
        {
          active: false,
          to: function() {
            let inside = document.getElementsByClassName('main-network-of-advertising-screens-in-retail')[0];
            let inside2 = document.getElementsByClassName('main-body')[0];
            let inside3 = document.getElementsByClassName('main-tag__content')[0];

            let scrollTop = {
              top: (inside.offsetTop + inside2.offsetTop - inside3.offsetHeight),
              behavior: 'smooth'
            };

            window.scrollTo(scrollTop);
          }
        },
        {
          active: false,
          to: function() {
            let inside = document.getElementsByClassName('main-modern-management-through-an-online-platform')[0];
            let inside2 = document.getElementsByClassName('main-body')[0];
            let inside3 = document.getElementsByClassName('main-tag__content')[0];

            let scrollTop = {
              top: (inside.offsetTop + inside2.offsetTop + inside3.offsetHeight),
              behavior: 'smooth'
            };

            window.scrollTo(scrollTop);
          }
        },
        {
          active: false,
          to: function() {
            let inside = document.getElementsByClassName('main-ways-to-measure-effectiveness')[0];
            let inside2 = document.getElementsByClassName('main-body')[0];
            let inside3 = document.getElementsByClassName('main-tag__content')[0];

            let scrollTop = {
              top: (inside.offsetTop + inside2.offsetTop + inside3.offsetHeight),
              behavior: 'smooth'
            };

            window.scrollTo(scrollTop);
          }
        }
      ],
      services: [
        {
          name: '– Indoor видеореклама в ритейле',
          active: true,
          dot: true,
          to: function() {
            let inside = document.getElementsByClassName('main-network-of-advertising-screens-in-retail')[0];
            let inside2 = document.getElementsByClassName('main-body')[0];
            let inside3 = document.getElementsByClassName('main-tag__content')[0];

            let scrollTop = {
              top: (inside.offsetTop + inside2.offsetTop - inside3.offsetHeight),
              behavior: 'smooth'
            };

            window.scrollTo(scrollTop);
          }
        },
        {
          name: '– Online retail рекламная сеть',
          active: false,
          dot: true
        },
        {
          name: '– Покупательские данные',
          active: false,
          dot: true
        },
        {
          name: '– Исследования',
          active: false,
          dot: true
        },
        {
          name: '– BTL промо',
          active: false,
          dot: true
        },
      ]
    }
  },
  methods: {
    onClickDot(index) {
      this.dots[index].to();
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/main-header.css');
</style>
