<template>
  <div class="main">
    <application-for-miscalculation-of-placement-in-r-m-sx-magnum
        :show-modal="showModal"
        @close="closeModal"
        v-if="showModal"
    >
    </application-for-miscalculation-of-placement-in-r-m-sx-magnum>
    <main-circles v-if="!showModal" />
    <main-dots :showModal="showModal" v-if="!showModal" />
    <main-header v-if="!showModal" />
    <div class="main-body" v-if="!showModal">
      <main-tag :showModal="showModal" />
      <main-network-of-advertising-screens-in-retail />
      <main-modern-management-through-an-online-platform />
      <main-ways-to-measure-effectiveness :showModal="showModal" />
    </div>
    <main-footer v-if="!showModal" @openModal="openModal" />
  </div>
</template>

<script>
import ApplicationForMiscalculationOfPlacementInRMSxMagnum from '../modals/ApplicationForMiscalculationOfPlacementInRMSxMagnum';
import MainCircles from './MainCircles'
import MainDots from './MainDots'
import MainHeader from './MainHeader'
import MainTag from './MainTag'
import MainNetworkOfAdvertisingScreensInRetail from './MainNetworkOfAdvertisingScreensInRetail'
import MainModernManagementThroughAnOnlinePlatform from './MainModernManagementThroughAnOnlinePlatform'
import MainWaysToMeasureEffectiveness from './MainWaysToMeasureEffectiveness'
import MainFooter from './MainFooter'

export default {
  name: 'Main',
  components: {
    ApplicationForMiscalculationOfPlacementInRMSxMagnum,
    MainCircles,
    MainDots,
    MainHeader,
    MainTag,
    MainNetworkOfAdvertisingScreensInRetail,
    MainModernManagementThroughAnOnlinePlatform,
    MainWaysToMeasureEffectiveness,
    MainFooter
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.$store.commit('SET_GENERALLY_SHOW_MODAL', true);
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('SET_GENERALLY_SHOW_MODAL', false);
    },
    checkWindow() {
      return window.innerWidth > 1075;
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/main.css');
</style>
