<template>
  <r-m-s-modal :title="title" @close="modalClose">
    <div class="rms-modal__forms">
      <div class="rms-modal__form rms-modal__form-name" v-for="(form, index) in forms">
        <span :class="'rms-modal__form-title' + (form.focus ? ' rms-modal__form-title-active' : '')">{{ form.title }}</span>
        <input :class="'rms-modal__form-input' + (form.focus ? ' rms-modal__form-input-active' : '')"
               type="text"
               @focus="onFocus(index)"
               @touchend="focusInput(index)"
               v-model="form.value"
               @keyup="onKeyUpInput($event, index)"
        >
      </div>
    </div>
    <div class="rms-modal__action">
      <button class="rms-modal__submit" type="button" @click="sendOrder" @touchend="sendOrder">Отправить</button>
      <div class="rms-modal__reset" @click="reset" @touchend="reset">Очистить форму</div>
    </div>
  </r-m-s-modal>
</template>

<script>
import RMSModal from '../../core/modals/RMSModal';
import ApiService from "../../../services/api";

export default {
  name: 'ApplicationForMiscalculationOfPlacementInRMSxMagnum',
  components: {
    RMSModal
  },
  data() {
    return {
      title: `<p>Заявка на просчет размещения в RMS x Magnum</p>
          <span>Сеть видеоэкранов в точках продаж</span>`,
      forms: [
        {
          name: 'name',
          title: 'Ваше имя*',
          focus: false,
          value: ''
        },
        {
          name: 'companie',
          title: 'Компания*',
          focus: false,
          value: ''
        },
        {
          name: 'email',
          title: 'E-mail*',
          focus: false,
          value: ''
        },
        {
          name: 'phone',
          title: 'Телефон*',
          focus: false,
          value: ''
        },
        {
          name: 'request_description',
          title: 'Опишите кратко ваш запрос*',
          focus: false,
          value: ''
        }
      ]
    }
  },
  props: {
    showModal: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    focusInput(index) {
      document.getElementsByClassName('rms-modal__form-input')[index].focus();
    },
    modalClose() {
      this.$emit('close');
    },
    onFocus(index) {
      // this.forms.map(e => {
      //   e.focus = false;
      //   return e;
      // });
      //
      // this.forms[index].focus = true;
    },
    reset() {
      this.forms.map(e => {
        e.value = '';
        e.focus = false;
        return e;
      })
    },
    onKeyUpInput(e, index) {
      if (e.key === 'Enter') {
        if (this.forms.length === index + 1) {
          this.sendOrder();
        } else {
          let inside = document.getElementsByClassName('rms-modal__form-input')[index + 1];
          inside.focus();
        }
      }
    },
    async sendOrder() {

      this.forms.map(e => {
        e.focus = false;
        return e;
      })

      let data = {
        data: {
          name: this.forms.find(e => e.name === 'name').value,
          companie: this.forms.find(e => e.name === 'companie').value,
          email: this.forms.find(e => e.name === 'email').value,
          phone: this.forms.find(e => e.name === 'phone').value,
          request_description: this.forms.find(e => e.name === 'request_description').value
        }
      };

      await ApiService.postOrders(data)
          .then(response => {
            console.log(response);

            let data = {
              type: 'success',
              message: 'Заявка успешно отправлена',
            }
            this.$store.dispatch('SET_NOTIFICATION', data);
          })
          .catch(error => {
            console.log(error);

            let data = {
              type: 'error',
              message: error.response.data.error.message,
            }
            this.$store.dispatch('SET_NOTIFICATION', data);

            this.forms.map(e => {
              if (e.name === error.response.data.error.details.errors[0].path[0]) {
                e.focus = true;
              }
              return e;
            });
          })
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/application-for-miscalculation-of-placementIn-r-m-sx-magnum.css');
</style>

