<template>
  <div class="main-network-of-advertising-screens-in-retail">
    <div class="main-network-of-advertising-screens-in-retail__content">
      <div class="main-network-of-advertising-screens-in-retail__title">Сеть рекламных экранов в ритейле #1</div>
      <div class="main-network-of-advertising-screens-in-retail__properties">
        <div class="main-network-of-advertising-screens-in-retail__property" v-for="property in properties">
          <div class="main-network-of-advertising-screens-in-retail__property-info">
            <div class="main-network-of-advertising-screens-in-retail__property-value">{{ property.value }}</div>
            <div class="main-network-of-advertising-screens-in-retail__property-name">{{ property.title }}</div>
          </div>
          <div class="main-network-of-advertising-screens-in-retail__property-image">
            <img :src="property.image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/api";
import ImageShop from '../../../assets/images/shop.png';
import ImageAdvertisingScreen from '../../../assets/images/advertising-screen.png';
import ImageShoppingCart from '../../../assets/images/shopping-cart.png';

export default {
  name: 'MainNetworkOfAdvertisingScreensInRetail',
  data() {
    return {
      properties: [
        {
          name: 'shops',
          title: 'существующих магазинов',
          value: '',
          image: ImageShop
        },
        {
          name: 'displays',
          title: 'рекламных экранов',
          value: '',
          image: ImageAdvertisingScreen
        },
        {
          name: 'purchases',
          title: 'покупок эжемесячно',
          value: '',
          image: ImageShoppingCart
        }
      ]
    }
  },
  mounted() {
    this.getInventory();
  },
  methods: {
    async getInventory() {
      await ApiService.getInventory()
          .then((response) => {
            response = response.data.data.attributes;
            console.log(response);
            this.properties.find(e => e.name === 'shops').value = response.shops;
            this.properties.find(e => e.name === 'displays').value = response.displays;
            this.properties.find(e => e.name === 'purchases').value = response.purchases;
          }).catch((error) => {
            console.log('RESPONSE ERROR');
            console.log(error);

            let data = {
              type: 'error',
              message: error.response.data.error.message,
            }
            this.$store.dispatch('SET_NOTIFICATION', data);
          });
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/main-network-of-advertising-screensIn-retail.css');
</style>

