<template>
  <div class="main-circles">
    <div class="main-circles__first-circle"></div>
    <div class="main-circles__second-circle"></div>
    <div class="main-circles__third-circle"></div>
    <div class="main-circles__fourth-circle"></div>
  </div>
</template>

<script>
export default {
  name: 'MainCircles'
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/main-circles.css');
</style>

