import Vue from 'vue'
import Vuex from 'vuex'
import Notifications from './modules/notifications'
import Modal from './modules/modal'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Notifications,
    Modal
  }
})
