<template>
  <div class="rms-modal">
    <div class="rms-modal__content">
      <div class="rms-modal__header">
        <div class="rms-modal__title-parent">
          <div class="rms-modal__title" v-html="title"></div>
        </div>
        <div class="rms-modal__close-parent">
          <div class="rms-modal__close" @click="closeModal" @touchend="closeModal">&#x2715;</div>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RMSModal',
  methods: {
    closeModal() {
      this.$emit('close');
    }
  },
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/core/modals/r-m-s-modal.css');
</style>

