<template>
  <div class="main-modern-management-through-an-online-platform">
    <div class="main-modern-management-through-an-online-platform__content">
      <div class="main-modern-management-through-an-online-platform__title">Современный менеджмент через онлайн платформу</div>
      <div class="main-modern-management-through-an-online-platform__services">
        <div class="main-modern-management-through-an-online-platform__service" v-for="service in services">
          <div class="main-modern-management-through-an-online-platform__service-image">
            <img :src="service.imageUrl">
          </div>
          <div class="main-modern-management-through-an-online-platform__service-name">{{ service.name }}</div>
        </div>
      </div>
      <div class="main-modern-management-through-an-online-platform__animation">
        <div class="main-modern-management-through-an-online-platform__animation-content">
          <svg>
            <use xlink:href="../../../assets/images/modern-management-through-an-online-platform/management.svg#icon"></use>
          </svg>
        </div>
        <div class="main-modern-management-through-an-online-platform__animation-content">
          <svg>
            <use xlink:href="../../../assets/images/modern-management-through-an-online-platform/management.svg#icon"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentralizedCampaignManagementThroughASingleSystem from '../../../assets/images/modern-management-through-an-online-platform/centralized-campaign-management-through-a-single-system.png';
import SingleCreativeFormatAndQuickLaunch from '../../../assets/images/modern-management-through-an-online-platform/single-creative-format-and-quick-launch.png';
import RemoteControl0fEachScreen from '../../../assets/images/modern-management-through-an-online-platform/remote-control-of-each-screen.png';
import RealTimeReportingAndClientAccess from '../../../assets/images/modern-management-through-an-online-platform/real-time-reporting-and-client-access.png';

export default {
  name: 'MainModernManagementThroughAnOnlinePlatform',
  data() {
    return {
      services: [
        {
          name: 'Централизованный менеджмент кампаний через единую систему',
          imageUrl: CentralizedCampaignManagementThroughASingleSystem
        },
        {
          name: 'Единый формат креатива и быстрый запуск',
          imageUrl: SingleCreativeFormatAndQuickLaunch
        },
        {
          name: 'Удаленный контроль работы каждого экрана',
          imageUrl: RemoteControl0fEachScreen
        },
        {
          name: 'Real-time отчетность и клиентские доступы',
          imageUrl: RealTimeReportingAndClientAccess
        }
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      let managementContent2 = document.getElementsByClassName('main-modern-management-through-an-online-platform__animation-content')[1];
      managementContent2.style.display = 'block';
    }, 3000);
  }
}
</script>

<style scoped>
@import url('../../../assets/css/modules/main/main-modern-management-through-an-online-platform.css');
</style>

